/* eslint-disable*/
import {
  Box,
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { userinfo } from 'feactures/user/userSlice';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  trialRegisterTiming,
  trialMallList
} from 'feactures/plan/premiumRegisterSlice';

const SettingPlan = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector(userinfo);
  const userPlan = userInfo.plan;
  const trial_mall_list = useSelector(trialMallList);

  // 申し込み中かを判定
  // premiumがFalseで、plan_idが1or2or3で、status=0のとき。
  const [Omousikomityuu, setOmousikomityuu] = useState({
    amazon: false,
    rakuten: false
  });

  const checkStatus = (platform) => {
    return (
      !userPlan[platform].premium &&
      [1, 2, 3].includes(userPlan[platform].plan_id) &&
      userPlan[platform].status === 0
    );
  };

  useEffect(() => {
    const newStatus = { ...Omousikomityuu };
    if (checkStatus('amazon')) {
      newStatus.amazon = true;
    }
    if (checkStatus('rakuten')) {
      newStatus.rakuten = true;
    }
    setOmousikomityuu(newStatus);
  }, [userPlan]);

  // 通常の申し込みボタンのURL
  const handleChangePlan = () => {
    navigate('/premium_register');
  };

  const modoru = () => {
    navigate(-1);
  };

  const GoriyouBox = () => (
    <Box
      bgcolor="#FFCFC9"
      color="#B10202"
      width="140px"
      py="6px"
      px="16px"
      margin="auto"
      fontWeight="bold"
      borderRadius={1}
    >
      ご利用中
    </Box>
  );

  const OmousikomityuuBox = () => (
    <Box
      bgcolor="#FFF2CC"
      color="#d15501"
      width="140px"
      py="6px"
      px="16px"
      margin="auto"
      fontWeight="bold"
      borderRadius={1}
    >
      お申込中
    </Box>
  );

  const MibaraiBox = () => (
    <Box
      bgcolor="#FFCFC9"
      color="#B10202"
      width="140px"
      py="6px"
      px="16px"
      margin="auto"
      fontWeight="bold"
      borderRadius={1}
    >
      未払い
    </Box>
  );

  const ChanghePlanButton = () => (
    <Button variant="outlined" onClick={handleChangePlan}>
      プラン変更お申込み
    </Button>
  );

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#fff8e5',
          height: 'auto',
          minHeight: '100vh',
          width: '100%'
          // display: 'flex',
          // alignItems: 'center'
        }}
      >
        <Container sx={{ width: '80%', py: 3 }}>
          <Typography fontSize="16px" fontWeight="bold">
            プラン
          </Typography>
          <Box mb={3} mt={1}>
            <TableContainer
              sx={{
                boxShadow: 0,
                border: 2,
                borderColor: '#E0E0E0'
              }}
              component={Paper}
            >
              <Table stickyHeader aria-label="a dense table" size="small">
                <TableHead>
                  {/* プラン */}
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        borderBottom: 2,
                        borderColor: '#E0E0E0',
                        bgcolor: '#F1F5F6',
                        fontWeight: 'bold',
                        fontSize: '14px',
                        width: '200px'
                      }}
                    >
                      プラン
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderLeft: 2,
                        borderBottom: 2,
                        borderColor: '#E0E0E0',
                        bgcolor: '#E1E6E9',
                        fontWeight: 'bold',
                        fontSize: '14px',
                        width: '200px'
                      }}
                    >
                      無料プラン
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderBottom: 2,
                        borderColor: '#E0E0E0',
                        bgcolor: '#FFEBF5',
                        fontWeight: 'bold',
                        fontSize: '14px',
                        width: '200px'
                      }}
                    >
                      有料プラン
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* 料金 */}
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        borderBottom: 1,
                        borderColor: '#E0E0E0',
                        fontSize: '14px',
                        fontWeight: 'bold'
                      }}
                    >
                      <Typography fontSize="20px" fontWeight="bold">
                        料金
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderLeft: 2,
                        borderBottom: 1,
                        borderColor: '#E0E0E0',
                        fontSize: '14px',
                        fontWeight: 'bold'
                      }}
                    >
                      <Typography fontSize="20px" fontWeight="bold">
                        ¥０
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderBottom: 1,
                        borderColor: '#E0E0E0',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        whiteSpace: 'nowrap'
                      }}
                    >
                      <Typography fontSize="20px" fontWeight="bold">
                        ¥55,000
                        <Typography fontSize="13px" as="span">
                          (税込み)/月
                        </Typography>
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {/* ユーザー数 */}
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        borderBottom: 2,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      ログイン可能ユーザー数
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderLeft: 2,
                        borderBottom: 2,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      制限なし
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderBottom: 2,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      制限なし
                    </TableCell>
                  </TableRow>
                  {/* KPIビュー */}
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        borderBottom: 1,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      KPIビュー
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderLeft: 2,
                        borderBottom: 1,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      〇
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderBottom: 1,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      〇
                    </TableCell>
                  </TableRow>
                  {/* マーケットビュー */}
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        borderBottom: 2,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      マーケットビュー
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderLeft: 2,
                        borderBottom: 2,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      〇
                      <Typography fontSize={10}>
                        ※カテゴリ・ブランド選択数に
                        <br />
                        制限があります
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderBottom: 2,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      〇
                    </TableCell>
                  </TableRow>
                  {/* 競合ビュー */}
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        borderBottom: 1,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      競合ビュー
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderLeft: 2,
                        borderBottom: 1,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      〇
                      <Typography fontSize={10}>
                        ※登録可能商品数に制限があります
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderBottom: 1,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      〇
                    </TableCell>
                  </TableRow>
                  {/* お申し込み */}
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        borderBottom: 1,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      Amazon
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderLeft: 2,
                        borderBottom: 1,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      {!userPlan.amazon?.premium && <GoriyouBox />}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderBottom: 1,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      {/* 有料 */}
                      {userPlan.amazon?.premium ? (
                        <GoriyouBox />
                      ) : Omousikomityuu.amazon ? (
                        <OmousikomityuuBox />
                      ) : userPlan.amazon?.status == -1 ? (
                        <MibaraiBox />
                      ) : (
                        <ChanghePlanButton />
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{ borderBottom: 0, fontSize: '14px' }}
                    >
                      楽天
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderLeft: 2,
                        borderBottom: 0,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      {/* 無料 無料利用中なら利用中をだすそれ以外は「changeplanbutton」　申し込み中なら利用中 */}
                      {!userPlan.rakuten?.premium && <GoriyouBox />}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderColor: '#E0E0E0',
                        borderBottom: 0,
                        fontSize: '14px'
                      }}
                    >
                      {/* 有料 */}
                      {userPlan.rakuten?.premium ? (
                        <GoriyouBox />
                      ) : Omousikomityuu.rakuten ? (
                        <OmousikomityuuBox />
                      ) : userPlan.rakuten?.status == -1 ? (
                        <MibaraiBox />
                      ) : (
                        <ChanghePlanButton />
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Typography color="textSecondary" variant="body1">
            <button onClick={() => modoru()}>←戻る</button>
          </Typography>
        </Container>
      </Box>
      {/* <PlanChangeModal plan_name={modalPlan} plan_id={modalPlanId} /> */}
    </>
  );
};

export default SettingPlan;
